<template>
  <div class="result-listing__item lowest-price position-relative">
    <div class="result-listing__item__block overflow-hidden">
      <div class="result-listing__item__block-top">
        <div class="row g-0">
          <div class="col-lg-10 col-md-12 left-col">
            <div class="result-listing__item__block_left h-100">
              <div class="result-listing__item__block_top">
                <div class="comprehensive-third-prty">
                  <div
                    class="comprehensive-third-prty_text"
                    :class="
                      detail.type == 'Comprehensive'
                        ? 'comprehensive'
                        : 'third-party'
                    "
                  >
                    <template v-if="language === 'ar'">
                      <span v-if="detail.type === 'Comprehensive'">
                        {{ $t("comprehensive") }}
                      </span>
                      <span v-else> {{ $t("third_party") }} </span>
                    </template>
                    <template v-else>
                      {{ detail.type }}
                    </template>
                  </div>
                </div>
                <div
                  class="result-listing__item__block_top_row d-flex flex-wrap align-items-center"
                >
                  <div class="partner-logo">
                    <img
                      :src="`${domain}${url}/img/${detail.image}`"
                      class="img-fluid"
                    />
                  </div>

                  <div class="partner-content">
                    <div class="partner-content_title">
                      <h5 class="mb-0">{{ detail.policy_name }}</h5>
                    </div>
                    <div class="partner-content_bottom d-flex">
                      <div class="partner-content_bottom_item cross-icon" v-if="detail.policy_type == 5">
                        <p>
                          {{ $t("own_damage") }}
                          
                          <div class="listing-ul">
                            <ul>
                              <li class="cross"
                              > </li>
                            </ul>
                          </div>
                        </p>
                      </div>
                      <div class="partner-content_bottom_item" v-else>
                        <p>
                          {{ $t("own_damage") }}
                          <span>
                            <span>{{ detail.own_damage_cover.value}}</span>
                          </span>
                        </p>
                      </div>
                      <div class="partner-content_bottom_item">
                        <p>
                          {{ $t("third_party_limit") }}
                          <span>{{ detail.third_party_limit.value}}</span>
                          
                        </p>
                      </div>
                      <div class="partner-content_bottom_item">
                        <p>
                          {{ $t("excess_amount") }}

                          <span v-if="detail.excess_amount">
                            {{ $filters.currency(detail.excess_amount.value) }}
                          </span>
                          <span v-else> {{ $filters.currency(0) }} </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="result-listing__item__block_medium">
                <div class="row gx-5">
                  <div class="col-md-3 ">
                    <div class="listing-ul">
                      <ul>
                       
                        <li
                          :class="!detail.agency_repair || (detail.agency_repair && detail.agency_repair.applicable === 0) ? 'cross' : 'check'"
                        >
                          {{ $t("agency_repair") }}
                        </li>
                        <li
                          :class="!detail.roadside_assistance || (detail.roadside_assistance && detail.roadside_assistance.applicable === 0) ? 'cross' : 'check'"
                        >
                          {{ $t("roadside_assistance") }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- checkbox for personal accident -->
                  <div class="col-md-5 ">
                    <div class="listing-ul">
                      <ul>
                        <li v-if="detail.personal_accident_driver > 0">
                          <div class="custom-checkbox">
                            
                              <input
                                class="styled-checkbox"
                                type="checkbox"
                                v-model="detail.value_personal_driver"
                                :id="`value_personal_driver_${numeric}`"
                                @change="
                                  personalPrice(
                                    detail.personal_accident_driver,
                                    detail
                                  )
                                "
                              />

                              <label :for="`value_personal_driver_${numeric}`">
                              {{ $t("personal_accident_driver") }} QAR {{ detail.personal_accident_driver }} 
                            </label>
                          </div>
                        </li>
                        <li v-if="detail.personal_accident_passenger > 0">
                            <div class="custom-checkbox">
                              <input
                                  class="styled-checkbox"
                                  type="checkbox"
                                  v-model="detail.value_personal_passenger"
                                  :id="`value_personal_passenger_${numeric}`"
                                  @change="
                                    personalPassengerPrice(
                                      detail.personal_accident_passenger,
                                      detail
                                    )
                                  "
                                />
                              <label :for="`value_personal_passenger_${numeric}`">
                                {{ $t("personal_accident_passenger") }} QAR {{ detail.personal_accident_passenger }}
                              </label>
                            </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                    <!-- extra add ons -->
                    <div class="col-lg-4 col-md-6 extra-add-col" v-if="detail.addons && detail.addons.length > 0">
                      <div class="listing-title">
                        <h6 class="">{{ $t("extra_addon") }}</h6>
                      </div>
                      <div
                        class="extra-addons d-flex align-items-center"
                        v-for="(addon, index) in detail.addons"
                        :key="index"
                      >
                        <div class="addons-checkbox">
                          <input
                            class="styled-checkbox"
                            id="replacement"
                            type="checkbox"
                            v-model="addon.addonCheck"
                            @change="addonAdd(addon, detail)"
                          />
                          <label> </label>
                        </div>
                        <div class="addons-text">
                          <div>
                            {{ language === 'ar' ? addon.add_on_ar : addon.add_on }}
                            <span>
                              <strong v-if="addon.addonValue">
                                {{ $filters.currency(addon.addonValue.price) }}
                              </strong>
                              
                              <el-select
                                @change="changeAddon(addon, detail)"
                                v-model="addon.addonValue"
                                filterable
                                value-key="details"
                                class="select-input addon-select"
                              >
                                <el-option
                                  class="addon-option"
                                  v-for="(item, index) in addon.addon_attr"
                                  :key="index"
                                  :label="language === 'ar' ? item.details_ar : item.details"
                                  :value="item"
                                />
                              </el-select>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-12 right-col">
            <div class="right-buy-now text-center">
              <div class="right-buy-now-top">
                <template v-if="detail.policy_price > 0">
                  <div class="premium-title">
                    <p>{{ $t("total_premium") }}</p>
                  </div>
                  <div class="price-title">
                    <h4 class="mb-0">
                      {{ $filters.currency(detail.policy_price) }}
                    </h4>
                  </div>
                </template>
                  <div class="premium-title" v-if="detail.policy_price === 0" >
                    <p class="contact-for-pricing" v-html="$t('contact_for_pricing')"></p>
                  </div>
                <div class="buy-now-button">
                  <!-- <a href="#" class="btn">Buy now</a> -->
                  <el-button :disabled="detail.policy_price === 0" @click.prevent="BuyNow(detail)" class="btn"
                    ><span class="text-center">
                      {{ $t("buy_now") }}
                    </span></el-button>
                </div>
                <div class="plan-details">
                  <a
                    v-if="detail.terms_url"
                    :href="`${domain}${url}/tc/${detail.terms_url}`"
                    target="_blank"
                    >{{ $t("plan_details") }}
                    <span class="icon-chevron-right"></span>
                  </a>
                </div>
              </div>
              <div class="right-buy-now-bottom">
                <div class="custom-checkbox">
                  <input
                    class="styled-checkbox"
                    :id="`add-to-compare1_${numeric}`"
                    type="checkbox"
                    :value="detail"
                    @change="$emit('checkToCompare', detail)"
                    :disabled="
                      detail.policy_price === 0 ||
                      (checkCompare.length >= limit &&
                      checkCompare.indexOf(detail) == -1)
                    "
                  />
                  <label :for="`add-to-compare1_${numeric}`"
                    ><span>{{ $t("add_to_compare") }}</span></label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="result-listing__item__block-bottom"
        :class="detail.value_read_more ? 'open' : ''"
      >
        <div class="result-listing__item__block-bottom-top">
          <div class="row justify-content-lg-between">
            <div class="col-lg-4 col-md-4 ">
              <!-- highlights -->
              <div class="listing-title">
                <h6 class="mb-0">{{ $t("car_coverage") }}</h6>
              </div>
              <div class="listing-ul">
                <ul>
                  <li
                    v-for="(coverage, index) in detail.benefit_car_coverage"
                    :key="index"
                    :class="coverage.applicable ? 'check' : 'cross'"
                  >
                   {{ coverage.title }}
                    <span v-if="coverage.value">{{ coverage.value }} </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 ">
              <!-- road-assistant -->
              <div class="listing-title">
                <h6 class="mb-0">{{ $t("extra_coverage") }}</h6>
              </div>
              <div class="listing-ul">
                <ul>
                  <li
                    v-for="(coverage, index) in detail.benefit_extra_coverage"
                    :key="index"
                    :class="coverage.applicable ? 'check' : 'cross'"
                  >
                   {{ coverage.title }}
                    <span v-if="coverage.value">{{ coverage.value }} </span>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 ">
              <!-- special-feactures -->
              <div class="listing-title">
                <h6 class="mb-0">{{ $t("special_feature") }}</h6>
              </div>
              <div class="listing-ul">
                <ul>
                  <li
                    v-for="(coverage, index) in detail.benefit_special_features"
                    :key="index"
                    :class="coverage.applicable ? 'check' : 'cross'"
                  >
                   {{ coverage.title }}
                    <span v-if="coverage.value">{{ coverage.value }} </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="more-details text-center more-details-custom"
      v-if="detail.type == 'Comprehensive' ? true : false"
    >
      <button
        class="btn"
        :class="detail.value_read_more ? 'active' : ''"
        @click="detail.value_read_more = !detail.value_read_more"
      >
        <i>
          {{
            !detail.value_read_more ? $t("more_details") : $t("less_details")
          }}</i
        >
        <span class="icon-dropdown-down"></span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps } from "vue";
import { store } from "@/store";
import Buy from "@/core/services/car/BuyNow";
import router from "@/router";
import { useReCaptcha } from "vue-recaptcha-v3";


  defineProps({
    detail: {
      type: Object,
      required: true,
    },
    checkCompare: {
      type: Array,
      required: false,
      default: [],
    },
    limit: {
      type: Number,
      required: true,
    },
    numeric: {
      type: Number,
      required: false,
    }
  })

    const captcha = useReCaptcha();

    const domain = ref(process.env.VUE_APP_ASSETS_DOMAIN);
    const url = ref(process.env.VUE_APP_INSURANCE_ASSETS_URL);
    const price = ref(0);

    // computed
    const compareData = computed(() => {
      return store.state.qoutes.compareData;
    });

    const addValueDriver = (val) => {
      console.log(val);
      if (val.value_personal_driver) {
        val.policy_price += val.personal_accident_driver;
      } else {
        val.policy_price -= val.personal_accident_driver;
      }
    };
    const addValuePassenger = (val) => {
      if (val.value_personal_passenger) {
        val.policy_price += val.personal_accident_passenger;
      } else {
        val.policy_price -= val.personal_accident_passenger;
      }
    };

    const addonAdd = (val, original) => {
      if (val.addonCheck) {
        original.policy_price += val.addonValue.price;
        val.prev_price = val.addonValue.price;
      } else {
        original.policy_price -= val.prev_price;
      }
    };

    const personalPrice = (val, original) => {
      // console.log({val: val, original: original})
      if (original.value_personal_driver) {
        original.policy_price += val;
      } else {
        original.policy_price -= val;
      }
    };

    const personalPassengerPrice = (val, original) => {
      if (original.value_personal_passenger) {
        original.policy_price += val;
      } else {
        original.policy_price -= val;
      }
    };

    // change event
    const changeAddon = (val, original) => {
      if(val.addonCheck) {
        original.policy_price += val.addonValue.price;
        original.policy_price -= val.prev_price;
        val.prev_price = val.addonValue.price;

      }
    };

    const checkDisable = computed(() => {
      return store.state.qoutes.isDisable;
    });

    const dataCompare = ref < Array < Object >> [];

    // handleClick
    const BuyNow = async (val) => {
      // load captcha
      await captcha?.recaptchaLoaded();
      // execute reCaptcha
      const token = await captcha?.executeRecaptcha("login");
      // console.log(token);

      const date = new Date();
      const expiryDate = date.setDate(date.getDate() + 30);
      const dateExpiry = new Date(expiryDate).toISOString();
      const data = {
        value: val,
        // uuid_dd: window.localStorage.getItem("fall_back_dd"),
        // uuid_vd: window.localStorage.getItem("fall_back"),
        payment_type: 1,
        order_description: "Online Generated Quotation",
        is_amend: 0,
        policy_sales_agent_id: 1,
        original_price: val.sale_price,
        policy_price: val.policy_price,
        sale_price: val.sale_price,
        language: language.value,
        is_agency_repair: val.agency_repair.value || 2,
        bai_car_insurance_policy_id: val.car_insurance_id,
        expiry_date_time: dateExpiry.split("T")[0] + " 23:59:59",
        insurance_provider_id: val.provider_id,
        // manufacturer_id: JSON.parse(
        //   window.localStorage.getItem("customer_detatils")
        // ).manufacturer_id,
        // model_id: JSON.parse(window.localStorage.getItem("customer_detatils"))
        //   .model_id,
        // trim_level_id: JSON.parse(
        //   window.localStorage.getItem("customer_detatils")
        // ).trim_level_id,
        uuid: router.currentRoute.value.query.uuid,
      };
      const driver_id = router.currentRoute.value.query.driver_id;
      if(driver_id) data.driver_id = driver_id
      
      if (token) {
        // Buy.getQoutationReference(data)
        // router.push({name: 'SecureChecout'})
        const result = await Buy.getQoutationReference(data);
        if (result.status == 200) {
          // router.push({ name: "SecureChecout" });
          const fullPath = router.currentRoute.value.fullPath
          const path = fullPath.split('?')
          window.location.href = `/secure-checkout?${path[1]}`
        }
      }
    };

    const language = computed(() => {
      return window.localStorage.getItem("language");
    });

</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
  .result-listing__item .price-tag-top {
    position: absolute;
    transform: rotate(-90deg);
    transform-origin: bottom left;
    top: 115px;
    left: 30px;
  }

  .result-listing__item__block-bottom.open {
    display: block;
    height: 100%;
    // opacity: 1;
  }
}

@media (max-width: 600px) {
  .result-listing__item__block-bottom.open {
    display: block;
    height: 100%;
    // opacity: 1;
  }
}

.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #02cd51 !important;
  border-color: #02cd51 !important;
}

.result-listing__item .price-tag-top {
  background: #0061ff;
  color: #fff;
  font-size: 15.5px;
  padding: 15px 18px 15px;
  display: inline-flex;
  text-transform: uppercase;
  font-weight: bold;
}

.result-listing__item__block-bottom {
  display: block;
  height: 0;
  //   opacity: 0;
  transition: height 0.4s;
  -webkit-transition: height 0.4s;
  -moz-transition: height 0.4s;
}

.result-listing__item__block_left
  .result-listing__item__block_top
  .comprehensive-third-prty
  .third-party {
  background: #8f8f8f !important;
}

.result-listing__item__block_left
  .result-listing__item__block_top
  .comprehensive-third-prty_text {
  display: inline-flex;
  border-radius: 0px 0px 34px 0;
  padding: 3px 15px 2px 18px;
  font-size: 14.5px;
  font-weight: 700;
  color: #fff;
}

.el-select-dropdown__item.selected {
  font-weight: normal !important;
  color: #64666a !important;
}

.contact-for-pricing {
  // font-size: 12px;
  color: #222261;
}
</style>
